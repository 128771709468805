import '@/lib/bootstrap/popper.min';
import '@/lib/bootstrap/bootstrap.min';
import '@/lib/bootstrap/bootstrap-toolkit.min';

$(document).ready(() => {
	/**
	 * Trigger events based on breakpoints being reached
	 */
	const viewport = ResponsiveBootstrapToolkit($);

	/**
	 * @description: Since there is no CSS ready event, check the DOM every
			100ms to see if the CSS has painted and we can determine the viewport size
	 */
	function getBreakpoint() {
		window.breakpoint = viewport.current();
		if (!window.breakpoint || window.breakpoint === 'unrecognized') {
			setTimeout(getBreakpoint, 100);
		} else {
			$(document).trigger('breakpoint.changed', [window.breakpoint]);
		}
	}
	getBreakpoint();

	$(window).on('resize', () => {
		const currentBreakPoint = viewport.current();
		// only trigger if breakpoint has changed
		if (currentBreakPoint !== window.breakpoint) {
			window.breakpoint = currentBreakPoint;
			$(document).trigger('breakpoint.changed', [currentBreakPoint]);
		}
	});

	// Fix bootstrap button overwriting jquery ui button
	$.fn.bootstrapBtn = $.fn.button.noConflict();
});
