/**
 * Replaces the reference array with the replacer array without breaking any
 * original references to the referenced array
 *
 * @param {list} reference - The array to replace
 * @param {list} replacer - The desired result of the referenced array
 */
export function replace(reference, replacer) {
	reference.splice(0, reference.length, ...replacer);
	reference.length = replacer.length;
}

/**
 * Separates a single array into multiple smaller arrays
 *
 * @param {Array} items - The original array
 * @param {Number} size - The size of the chunks. Default 25
 * @return {[]|*[]} The chunked array
 */
export function chunkify(items, size = 25) {
	if (!items || items.length === 0) {
		return [];
	}
	const chunks = [];
	for (let offset = 0; offset < items.length; offset += size) {
		chunks.push(items.slice(offset, offset + size));
	}

	return chunks;
}

export default {
	replace,
	chunkify
};
