window.jQuery = require('@/lib/jquery/jquery-3.6.1.min');

window.$ = window.jQuery;

require('@/lib/jquery/jquery-ui.min');
require('@/lib/jquery/jquery.ui.widget.min');
require('@/lib/jquery/jquery.validate.min');
require('@/lib/jquery/additional-methods.min');
require('@/lib/jquery/jquery.ajaxmanager');
require('@/lib/jquery/PT.Env');

// Update the ui tooltip to not clash with bootstrap
$.widget.bridge('uitooltip', $.ui.tooltip);

// Extends the ui dialog so we can update specific functions
$.widget('ui.dialog', $.ui.dialog, {
	/**
	 * Overrides to allow interaction with other dialog implementations so an open jQuery dialog
	 * doesn't steal focus
	 *
	 * @param {$.Event} event - The triggered jQuery event
	 * @returns {boolean} True if interaction is allowed, false otherwise
	 * @private
	 */
	_allowInteraction(event) {
		const target = $(event.target);
		return !!target.closest('.ui-dialog, .ui-datepicker, .pt-dialog-container, .pt-slide-out-dialog-container').length;
	},

	/**
	 * Overrides to place the "top" most jQuery dialog at the end of the parent since z-index is now
	 * static (like the other dialog interactions)
	 *
	 * @param {*} focusContent - The content of the focus event
	 * @param {boolean} suppressFocusTrigger - Whether to not trigger the focus event
	 * @returns {boolean} True if the dialog was moved, false otherwise
	 * @private
	 */
	_moveToTop(focusContent, suppressFocusTrigger) {
		let moved = false;
		const parent = this._appendTo();
		// We only care about the position relative to the other dialog instances
		const children = parent.children('.ui-dialog:visible, .pt-dialog-container:visible, .pt-slide-out-dialog-container:visible');
		if (children[children.length - 1] !== this.uiDialog.get(0)) {
			parent.append(this.uiDialog);
			if (this.overlay) {
				this.overlay.insertBefore(this.uiDialog);
			}
			moved = true;

			if (!suppressFocusTrigger) {
				this._trigger('focus', focusContent);
			}
		}

		return moved;
	},

	/**
	 * Overrides the overlay to be appended before the dialog
	 */
	open() {
		if (this._isOpen) {
			this._moveToTop();
			this._focusTabbable();
		} else {
			this._isOpen = true;
			this.opener = $($.ui.safeActiveElement(this.document[0]));
			this._size();
			this._position();
			this._createOverlay();
			this._moveToTop(null, true);

			if (this.overlay) {
				this.overlay.insertBefore(this.uiDialog);
			}
			this._show(this.uiDialog, this.options.show, () => {
				this._focusTabbable();
				this._trigger('focus');
			});
			this._makeFocusTarget();
			this._trigger('open');
		}
	}
});
